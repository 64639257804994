import React, { useEffect, useState } from 'react';

const Form = () => {
    const [text, setText] = useState("");
    const [history, setHistory] = useState([]);

    useEffect(() => {
        const userRooms = localStorage.getItem('history') || '[]';
        const history = JSON.parse(userRooms);
        setHistory(history);
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        window.location.href = `/rooms/${text}`;
        const room = text;
        const userRooms = localStorage.getItem('history') || '[]';
        const history = JSON.parse(userRooms);

        // Check if the room already exists
        const existingRoomIndex = history.findIndex(item => item.room === room);
        if (existingRoomIndex !== -1) {
            // Update the timestamp of the existing room
            history[existingRoomIndex].time = Date.now();
        } else {
            // Add a new room entry
            history.push({ room: room, time: Date.now() });
        }

        localStorage.setItem('history', JSON.stringify(history));
        setHistory(history); // Update the state to reflect changes
    };

    function timeSince(timeStamp) {
        var now = new Date(),
            secondsPast = (now.getTime() - timeStamp) / 1000;
        if (secondsPast < 60) {
            return parseInt(secondsPast) + 's';
        }
        if (secondsPast < 3600) {
            return parseInt(secondsPast / 60) + 'm';
        }
        if (secondsPast <= 86400) {
            return parseInt(secondsPast / 3600) + 'h';
        }
        if (secondsPast > 86400) {
            const day = new Date(timeStamp).getDate();
            const month = new Date(timeStamp).toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
            const year = new Date(timeStamp).getFullYear() == now.getFullYear() ? "" : " " + new Date(timeStamp).getFullYear();
            return day + " " + month + year;
        }
    };

    return (
        <div className="sign-in-container flex flex-col items-center justify-center">
            <form onSubmit={submitForm}>
                <input required value={text} placeholder="Enter Room ID" onChange={(e) => setText(e.target.value)} type="text" className="room-input" />
                <small style={{ color: '#1b1f23', textAlign: 'center' }}>Creates a new room if it doesn't already exist.</small>
                <br />
                <button type="submit" className="submit-btn"><span className="submit-btnspan">Submit</span></button>
            </form>
            {(history && history.length > 0) && (
                <>
                    <div className="mt-8 block mx-auto">
                        <h1 className="text-4xl text-center font-bold">Recent Chat Rooms</h1>
                        <div className="history-container mt-8 flex flex-row justify-center gap-[24px] cursor-pointer items-center flex-wrap">
                            {history.map(room => {
                                return (<a key={room.room} data-aos="fade-in" data-aos-duration="1000" target="_blank" href={`/rooms/${room.room}`} className="[&amp;>div]:w-full"><div style={{ backdropFilter: 'blur(4.5px)', backgroundClip: 'padding-box' }} className={`paste-card rounded-lg bg-[#ffffff0a] text-white shadow`}>
                                    <div className="card-top flex text-lg items-center justify-between">
                                        <div className="flex gap-3 text-lg items-center">
                                            <div className="card-title text-black font-bold text-xl">{room.room}</div>
                                        </div>
                                        <div className="cursor-pointer bg-[#ffffff0d] hover:bg-[#ffffff0a] hover:text-[#7760fe] hover:scale-105 rounded-[7px] py-[2px] px-[4px]">
                                            <i className="cursor-pointer fa-light fa-link text-[#909090] text-lg"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer flex justify-between items-center text-[#09090b]">
                                        <div>Last joined {timeSince(room.time) + " " + (((Date.now() - room.time) / 1000) > 86400 ? '' : 'ago')}</div>
                                    </div>
                                </div>
                                </a>)
                            })}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Form
