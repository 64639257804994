import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const ProfileButton = ({ user, handleSignOut }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="sign-out top-2 left-2" ref={dropdownRef}>
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="relative h-10 w-10 rounded-full overflow-hidden border-2 border-[#05202e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#05202e] transition-all duration-200 hover:scale-105 flex items-center justify-center"
        aria-expanded={isOpen}
      >
        {user.photoURL ? (
          <img 
            src={user.photoURL} 
            alt="Profile" 
            className="h-full w-full object-cover object-center"
          />
        ) : (
          <div className="h-full w-full flex items-center justify-center bg-gradient-to-br from-indigo-500 to-purple-600 text-white font-medium text-lg">
            {user.displayName ? user.displayName.charAt(0).toUpperCase() : '?'}
          </div>
        )}
      </button>

      {isOpen && (
        <div className="absolute left-0 mt-2 w-56 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50 transform transition-all duration-200 origin-top-left">
          {/* User Info Section */}
          <div className="px-4 py-3">
            <p className="text-sm font-medium text-gray-900 truncate">
              {user.displayName || 'User'}
            </p>
            <p className="text-sm text-gray-500 truncate">
              {user.email || ''}
            </p>
          </div>
          <div className="py-1">
            <button 
              onClick={() => {
                setIsOpen(false);
                handleSignOut();
              }}
              className="group flex w-full items-center px-4 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700 transition-colors duration-150"
            >
              <svg className="mr-3 h-5 w-5 text-red-500 group-hover:text-red-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V4a1 1 0 00-1-1H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
                <path d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              Sign out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const Logout = ({ auth }) => {
  const history = useHistory()
  const logOut = () => {
    auth.signOut();
    history.push("/")
  }
  return auth.currentUser && (
    <ProfileButton 
      user={auth.currentUser} 
      handleSignOut={logOut} 
    />
  )
}

export default Logout
