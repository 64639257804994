import React, { useState, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Import the CSS
import 'tippy.js/themes/light.css'; // Import a base theme to extend

const Message = ({ message, auth }) => {
  const { text, uid, photoURL, username } = message;
  console.log(message);
  const position = uid === auth.currentUser.uid ? "right" : null;
  
  // Custom styles for Tippy
  const tippyStyles = {
    backgroundColor: '#072d45',
    color: 'white',
    padding: '8px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    borderColor: '#333'
  };
  
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';

    const date = timestamp.seconds ?
      new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000) :
      new Date();

    return date.toLocaleString('en-GB', {
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };
  
  const formatDetailedTimestamp = (timestamp) => {
    if (!timestamp) return '';
    
    const date = timestamp.seconds ?
      new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000) :
      new Date();
      
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    };
    
    return date.toLocaleString('en-US', options);
  };

  return (
    <>
      <div className="msg-row">
        <div className={`msg-text ${position}`}>
          <div className="msg-content">
            <div className="flex flex-row items-center space-between mb-[7px]">
              <h2>{username}</h2>
              <span className="mx-2 text-gray-400">•</span>
              <Tippy 
                content={formatDetailedTimestamp(message.createdAt)}
                placement="top"
                arrow={true}
                theme="custom"
                popperOptions={{
                  modifiers: [
                    {
                      name: 'arrow',
                      options: {
                        element: '[data-tippy-root] .tippy-arrow',
                        padding: 5,
                      },
                    },
                  ],
                }}
                onCreate={instance => {
                  // Apply custom styles to the tooltip
                  const box = instance.popper.querySelector('.tippy-box');
                  const arrow = instance.popper.querySelector('.tippy-arrow');
                  
                  Object.assign(box.style, tippyStyles);
                  if (arrow) {
                    arrow.style.color = '#05202e';
                  }
                }}
              >
                <p className="cursor-pointer text-xs text-gray-400 font-light italic">
                  {formatTimestamp(message.createdAt)}
                </p>
              </Tippy>
            </div>
            <p>{text}</p>
          </div>
        </div>
        <img src={photoURL} />
      </div>
    </>
  )
}

export default Message
